<template>
  <main class="h-full p-10">
    <slot></slot>
    <InertiaToastManager class="z-50 mt-32" />
  </main>
</template>

<script>
import InertiaToastManager from '@/components/toasts/InertiaToastManager.vue';
export default {
  components: { InertiaToastManager },
};
</script>

import Vue from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue';
import { resolvePageComponent } from 'vite-plugin-laravel/inertia';
import { InertiaProgress } from '@inertiajs/progress';
import InertiaLayout from './layouts/InertiaLayoutGray.vue';

InertiaProgress.init({
  color: '#00aaff',
});

createInertiaApp({
  resolve: (name) => {
    const page = resolvePageComponent(
      name,
      import.meta.glob('@/Pages/**/*.vue')
    );
    page.then((module) => {
      module.layout = module.layout || InertiaLayout;
    });
    return page;
  },
  setup({ el, App, props, plugin }) {
    Vue.use(plugin);
    // Vue.config.devtools = true;

    new Vue({
      render: (h) => h(App, props),
    }).$mount(el);
  },
});

<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex px-4 py-6 sm:p-6"
  >
    <transition-group
      tag="div"
      class="flex w-full flex-col items-center space-y-4 sm:items-end"
      enter-active-class="transition duration-300 ease-out transform"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <Toast
        v-for="toast in toasts"
        :key="toast.id"
        :toast="toast"
        @remove-toast="removeToast(toast.id)"
      />
    </transition-group>
  </div>
</template>

<script>
import Toast from '@/components/toasts/Toast.vue';
import uniqBy from 'lodash/uniqBy';
import { getToasts, clearToast } from '@/state/toasts.js';

export default {
  name: 'InertiaToastManager',
  components: { Toast },
  data() {
    return {
      inertiaToasts: [...this.$page.props.toasts],
    };
  },
  computed: {
    toasts() {
      return [...this.inertiaToasts, ...getToasts()];
    },
  },
  watch: {
    '$page.url'() {
      this.inertiaToasts = [];
    },
    '$page.props.toasts'(toasts) {
      this.inertiaToasts = uniqBy([...this.inertiaToasts, ...toasts], 'id');
    },
  },
  methods: {
    removeToast(id) {
      this.inertiaToasts = this.inertiaToasts.filter(
        (toast) => toast.id !== id
      );
      clearToast(id);
    },
  },
};
</script>

<template>
  <InertiaLayout class="bg-gray-3">
    <slot></slot>
  </InertiaLayout>
</template>

<script>
import InertiaLayout from './InertiaLayout.vue';
export default {
  components: { InertiaLayout },
};
</script>
